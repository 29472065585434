import { Component, ViewEncapsulation } from '@angular/core';
import { CommmonUtil } from '../utils/common.util';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrl: './user-login.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class UserLoginComponent {
 currentYear: number;
 constructor(private commonUtil: CommmonUtil){
    this.currentYear = this.commonUtil.getCurrentYear();
  }
}
